import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { SkewMainContainer } from "../../components/Container";
import { SectionDescribe, SectionTitle } from "../../components/Title";
import {
  Culture1,
  Culture2,
  Culture3,
  Culture4,
  culture_cover,
} from "../../images/recruitment";
import WhyChooseUsView from "./WhyChooseUsView";

const CompanyCultureView = () => {
  const { t } = useTranslation();
  const cultureLists = [
    {
      title: t("第一性原理"),
      desc: t(
        "具备独立分析事情的思维,能够分解问题到最底层, 抓住问题的本质, 看到解决问题的方式, 内化成解决问题的能力, 看到执行的路径, 发现风险点位, 客观评价自己的差距, 不断努力提高的动力, 有承担责任的勇气；"
      ),
      icon: <Culture1 />,
    },
    {
      title: t("精益文化"),
      desc: t(
        "保持精益文化(Lean Culture)，并以时刻关注成本（cost-conscious）的方式运行团队，才能给客户带来有竞争力的持续价值；"
      ),
      icon: <Culture2 />,
    },
    {
      title: t("创业精神"),
      desc: t(
        "坚持正道成功，信任与授权，坚持创始人精神；这是给我们每位合伙人提出的要求，要相信一线同学，他们最接近前线，是听得到炮火声音的人，他们更有发言权参与到事情的决策中，授权给他们，让他们充分发挥好自己的优势与特长。"
      ),
      icon: <Culture3 />,
    },
    {
      title: t("做正确的事"),
      desc: t(
        "不负青春，做到最好；这是给到我们每一个星尘同学的期望，青春无价，我们选择了星尘， 即选择了一种不负青春的方式，做到自己最好，与团队共同成长;"
      ),
      icon: <Culture4 />,
    },
  ];
  return (
    <SkewMainContainer fillClassName="bg-white">
      <WhyChooseUsView />
      <SectionTitle>{t("公司文化与氛围")}</SectionTitle>
      <SectionDescribe className="mt-6">
        {t(
          "公司业务保持快速增长，规模也越来越大，期待您加入我们，与星尘共同成长。"
        )}
      </SectionDescribe>
      <div className="grid grid-col-2 sm:grid-cols-4 gap-8 mt-12">
        {cultureLists.map(({ title, desc, icon }) => {
          return (
            <div key={title}>
              {icon}
              <h4 className="my-4 text-lg font-bold">{title}</h4>
              <p className="text-color-blue-gary">{desc}</p>
            </div>
          );
        })}
      </div>
      <img src={culture_cover} alt="" />
      <p className="text-xs text-center mb-2">
        {t(
          "在星尘工作，享受便利的交通，公司全体提供无限量零食、咖啡、饮料、水果，"
        )}
      </p>
      <p className="text-xs text-center">
        {t(
          "同时我们倡导弹性工作时间与远程办公，在这个特殊时期，充分考虑每一位员工的需求。"
        )}
      </p>
    </SkewMainContainer>
  );
};
export default CompanyCultureView;
