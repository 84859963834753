import classNames from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import Button from "../../components/Button";
import { MainContainer, SkewContainer } from "../../components/Container";
import {
  StripeColor,
  StripeColorContainer,
} from "../../components/StripeColor";
import { SectionTitle } from "../../components/Title";
import {
  characterRight,
  Character_1,
  Character_2,
  Character_3,
} from "../../images/recruitment";

const CharacterView = () => {
  const { t } = useTranslation();
  const characterLists = [
    {
      title: t("专注于数据自动处理技术"),
      desc: t(
        "星尘是国内首家专注于数据自动处理技术的企业。我们通过“人机结合”的方式解决这一行业本质问题。星尘自主研发了AI数据处理平台Stardust，在云端即可实现数据结构化信息大批量处理。针对不同的数据类型和数据结构化需求，开发了图像标注工具、语音标注工具、文本NLP标注工具和3D标注工具。星尘通过深耕自动驾驶 、语音语言识别、智慧城市、电商零售、机器人 、智慧医疗 、在线教育、金融保险 、工业制造、广告推荐等多个人工智能应用场景，显著提高了数据生产的质量和专业度，帮助客户将其算法能力达到行业最领先。"
      ),
      icon: <Character_1 className="flex-none w-14" />,
    },
    {
      title: t("影响力快速扩大"),
      desc: t(
        "我们已成为工信部数据资源服务创新专业委员会常任理事单位、华为云生态严选合作伙伴、京东AI战略合作人工智能标注平台、百度EasyDL生态合作伙伴、百度AI加速器第七期成员、以及AWS全球生态合作伙伴。"
      ),
      icon: <Character_2 className="flex-none w-14" />,
    },
    {
      title: t(
        "积累了来自海内外上市公司、高校研究所、政府事业单位和创业独角兽等多个领域广泛的客户基础。"
      ),
      desc: t(
        "星尘拥有丰富的行业经验和良好的业内口碑，目前，星尘通过行业领先的技术实力，优秀的客户服务水平和年数据处理量破亿的运营能力，积累了来自海内外高校研究所、政府和事业单位、上市公司和创业独角兽等多个领域广泛的客户基础。星尘的客户包括百度、阿里、腾讯、头条等一线科技公司，上汽、吉利、博世等国内外汽车主机厂和tier1制造商，也有中科大、清华、港中大、斯坦福、MIT等一流学术机构，还有新华社、人民日报、航天院、中传国家融媒体研究院等国家研究机构，为全球人工智能的发展和落地起到了极大的加速作用。可以说，星尘生产的数据重新定义了客户AI产品智能化的高度。"
      ),
      icon: <Character_3 className="flex-none w-14" />,
    },
  ];
  return (
    <SkewContainer
      fillClassName="light-white-bg z-10"
      skewBottomChildren={
        <>
          <StripeColorContainer className="-bottom-8">
            <StripeColor className="bg-[#6775DE] w-64" />
          </StripeColorContainer>
          <StripeColorContainer className="right-0 flex flex-col items-end -bottom-16">
            <StripeColor className="bg-[#CCEFFD] w-80" />
            <StripeColor className="bg-[#ADE6FF] w-72" />
          </StripeColorContainer>
        </>
      }
    >
      <MainContainer>
        <SectionTitle>{t("星尘特点")}</SectionTitle>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 mt-10">
          <div>
            {characterLists.map(({ title, desc, icon }, index) => (
              <div
                key={title}
                className={classNames("flex mb-4", index === 2 && "-mb-4")}
              >
                {icon}
                <div className="mb-2 ml-4">
                  <h4 className="text-lg font-bold mb-2">{title}</h4>
                  <p className="text-color-blue-gary leading-7">{desc}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="bg-white rounded-md shadow-offset-15">
            <img src={characterRight} alt="" />
            <div className="px-12 pt-8 pb-12">
              <h4 className="text-lg mb-6">{t("人工智能的三要素")}</h4>
              <p className="text-color-blue-gary mb-16 sm:mb-20 leading-6">
                {t(
                  "AI的发展离不开三大要素：算法、算力、数据。而数据属于AI研发的前提和不可或缺的资源。随着全球AI应用的不断落地，各大科技公司对数据的需求暴增。传统的数据结构化服务由大量人工对数据进行标记和处理，但成本高、质量不能保证，成为了行业痛点。"
                )}
              </p>
              <Button onClick={() => window.open("mailto:hr@stardust.ai")}>
                {t("联系HR")}
              </Button>
            </div>
          </div>
        </div>
      </MainContainer>
    </SkewContainer>
  );
};

export default CharacterView;
