import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { MainContainer, SkewContainer } from "../../components/Container";
import {
  StripeColor,
  StripeColorContainer,
} from "../../components/StripeColor";
import { SectionDescribe, SectionTitle } from "../../components/Title";
import {
  aboutProverb,
  SVGAboutFlow,
  SVGAboutFlow_en,
} from "../../images/recruitment";
import { useLanguage } from "../../utils/utils";

const AboutView = () => {
  const { t } = useTranslation();
  const { isEnUs } = useLanguage();
  const descTexts = [
    {
      title: t("我们是谁？"),
      desc: t(
        "我们是一群极度热爱技术的工程师，我们在国内外的公司、项目中做出过非常核心的贡献。我们的成员曾经创造过在github 上面曾排名第一的Swift开源项目。我们也有人从0开始独自实现了一套和ReactNative类似的框架和工具链。"
      ),
    },
    {
      title: t("我们在做什么？"),
      desc: t(
        "我们现在主要做的事情是数据标注，结构化的数据是人工智能行业的石油。这是一个一眼看来很简单的事情，但仔细去想象和思考却又非常耐人寻味的问题。我们在开发一套效率极高，通用型极强的全方位揽括几乎所有数据类型的标注平台。"
      ),
    },
    {
      title: t("我们做了什么？"),
      desc: t(
        "我们打造了一套“大规模人机协同的数据任务处理协同”，实现了模型的生命周期中的数据生产、数据管理、模型训练、模型部署等环节。它是世界先进的一套AI数据处理和生产系统。星尘数据提供了数据质量、成本、可扩展性的最佳解决方式。"
      ),
    },
  ];

  return (
    <SkewContainer
      fillClassName="bg-white"
      className="z-20"
      skewTopChildren={
        <>
          <StripeColorContainer className="-top-8">
            <StripeColor className="bg-[#F6D67A] w-80" />
            <StripeColor className="bg-[#CCEFFD] w-56" />
          </StripeColorContainer>
          <StripeColorContainer className="absolute-x-center -top-8">
            <StripeColor className="bg-[#FEDBFF] w-80" />
          </StripeColorContainer>
          <StripeColorContainer className="right-0 flex flex-col items-end -top-8">
            <StripeColor className="bg-[#E6EBF1] w-36" />
            <StripeColor className="bg-[#6775DE] w-80" />
          </StripeColorContainer>
        </>
      }
      skewBottomChildren={
        <>
          <StripeColorContainer className="bottom-0">
            <StripeColor className="bg-[#FEDBFF] w-80" />
          </StripeColorContainer>
          <StripeColorContainer className="right-0 flex flex-col items-end -bottom-8">
            <StripeColor className="bg-[#F6D67A] w-36" />
            <StripeColor className="bg-[#6775DE] w-80" />
          </StripeColorContainer>
        </>
      }
    >
      <MainContainer>
        <SectionTitle id="companyIntroduction" className="text-center sm:mt-40">
          {t("我们正在经历一场伟大的变革")}
        </SectionTitle>
        <div className="mt-12 px-6 sm:px-32">
          {isEnUs ? <SVGAboutFlow_en /> : <SVGAboutFlow />}
        </div>
        <SectionTitle className="mt-12">{t("关于星尘")}</SectionTitle>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div>
            <SectionDescribe className="mt-6">
              {t(
                "星尘专注于通过行业领先的深度学习模型来提升AI数据自动化生产率，致力于为日益增长的AI数据需求提供标准化的解决方案。"
              )}
            </SectionDescribe>
            <SectionDescribe className="mt-6">
              {t(
                "星尘纪元成立于2017年5月，是一家人工智能训练数据和模型供应商。我们提供有效的训练数据和行业领先的深度学习模型来帮助提升机器学习中的数据、模型训练、效果评测等环节。"
              )}
            </SectionDescribe>
            <SectionDescribe className="mt-6">
              {t(
                "星尘数据专注于AI训练数据采集/标注，提供高质量的训练数据和行业领先的数据标注系统来帮助提升机器学习中的数据生产、数据交易、模型训练、模型优化等环节，并为您的业务给出最佳的AI解决方案。"
              )}
            </SectionDescribe>
            <SectionDescribe className="mt-6">
              {t(
                "数据作为AI算法的电能，如何降低成本、保证质量、大规模生产是行业的痛点。我们的数据标注平台Stardust提供AI数据全流程标注和质量管理，它提供了数据质量、成本、可扩展性的最佳解决方式。"
              )}
            </SectionDescribe>
          </div>
          <div className="relative">
            <img
              className="sm:absolute w-full sm:w-[640px] max-w-none"
              src={aboutProverb}
              alt=""
            />
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 pt-16 sm:pt-20">
          {descTexts.map(({ title, desc }) => (
            <div key={title}>
              <h4 className="text-color-primary text-lg mb-3 font-bold">
                {title}
              </h4>
              <p className="text-color-blue-gary">{desc}</p>
            </div>
          ))}
        </div>
      </MainContainer>
    </SkewContainer>
  );
};

export default AboutView;
