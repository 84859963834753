import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { MainContainer, SkewContainer } from "../../components/Container";
import {
  StripeColor,
  StripeColorContainer,
} from "../../components/StripeColor";
import { SectionTitle } from "../../components/Title";
import { time_node, time_node_en } from "../../images/recruitment";
import { useLanguage } from "../../utils/utils";

const TimeNodeView = () => {
  const { t } = useTranslation();
  const { isEnUs } = useLanguage();
  return (
    <SkewContainer
      fillClassName="light-white-bg z-10"
      skewTopChildren={
        <StripeColorContainer className="flex flex-col items-start -top-8">
          <StripeColor className="bg-[#CCEFFD] w-32" />
          <StripeColor className="bg-[#ADE6FF] w-48" />
        </StripeColorContainer>
      }
      skewBottomChildren={
        <>
          <StripeColorContainer className="-bottom-8">
            <StripeColor className="bg-[#FEDBFF] w-64" />
          </StripeColorContainer>
          <StripeColorContainer className="right-0 flex flex-col items-end -bottom-16">
            <StripeColor className="bg-[#F6D67A] w-24" />
            <StripeColor className="bg-[#6775DE] w-56" />
          </StripeColorContainer>
        </>
      }
    >
      <MainContainer>
        <SectionTitle>{t("重要时间节点")}</SectionTitle>
        <div className="overflow-x-scroll w-full">
          <img
            className="min-w-[920px]"
            src={isEnUs ? time_node_en : time_node}
            alt=""
          />
        </div>
      </MainContainer>
    </SkewContainer>
  );
};
export default TimeNodeView;
