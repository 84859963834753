import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { FC } from "react";
import { RecruitmentPageQuery } from "../../graphql-types";
import {
  MainContainer,
  PageContainer,
  TopSkewBgContainer,
} from "../components/Container";
import Footer from "../components/Footer";
import Header, { ThemeEnum } from "../components/Header";
import Metadata from "../components/Metadata";
import { PageDescribe, PageTitle } from "../components/Title";
import { recruitmentCover, RecruitmentIcon } from "../images/recruitment";
import { usePageMetadata } from "../metadata/page";
import AboutView from "../Views/recruitmentViews/AboutView";
import CharacterView from "../Views/recruitmentViews/CharacterView";
import CompanyCultureView from "../Views/recruitmentViews/CompanyCultureView";
import CurrentPositionView from "../Views/recruitmentViews/CurrentPositionView/CurrentPositionView";
import IndustryProspectsView from "../Views/recruitmentViews/IndustryProspectsView";
import TimeNodeView from "../Views/recruitmentViews/TimeNodeView";
import VoiceEmployeesView from "../Views/recruitmentViews/VoiceEmployeesView";
import { PageTitleWithIcon } from "./price";

const RecruitmentPage: FC<{ data: RecruitmentPageQuery }> = ({ data }) => {
  const { recruitment } = usePageMetadata();
  const { t } = useTranslation();
  return (
    <PageContainer>
      <Header defaultTheme={ThemeEnum.dark} />
      <Metadata {...recruitment} />
      <TopSkewBgContainer className="h-[720px] light-white-bg" />
      <MainContainer className="mb-20">
        <PageTitleWithIcon title={t("人才招聘")} icon={<RecruitmentIcon />} />
        <div className="w-full sm:w-[560px] pb-36">
          <PageTitle>{t("加入星尘，共创未来")}</PageTitle>
          <PageDescribe className="mt-6">
            {t(
              "宇宙由无数颗巨大的超星系团构成，星系周围是大团看不见的未知太空，加入星尘，每位员工都能成为耀眼的新星，让我们共同拓展星辰大海。"
            )}
          </PageDescribe>
        </div>
      </MainContainer>
      <div className="absolute top-[400px] sm:top-60 h-40 px-6 sm:px-16 z-30 w-full">
        <img
          className="max-w-[1680px] w-full relative-x-center"
          src={recruitmentCover}
          alt=""
        />
      </div>
      <AboutView />
      <CharacterView />
      <IndustryProspectsView />
      <TimeNodeView />
      <CompanyCultureView />
      <VoiceEmployeesView />
      <CurrentPositionView
        departments={data.allContentfulRecruitingDepartment?.nodes ?? []}
        types={data.allContentfulRecruitmentType?.nodes ?? []}
        jobs={data.allContentfulJobs?.nodes ?? []}
        locations={data.allContentfulRecruitmentLocation?.nodes ?? []}
      />
      <Footer />
    </PageContainer>
  );
};
export default RecruitmentPage;

export const query = graphql`
  query RecruitmentPage($language: String!) {
    ...AllLangauages
    allContentfulJobs(filter: { node_locale: { eq: $language } }) {
      nodes {
        slug
        name
        jobUrl
        type {
          title
          slug
        }
        location {
          title
          slug
        }
        department {
          slug
          title
        }
        details {
          details
        }
      }
    }
    allContentfulRecruitingDepartment(
      filter: { node_locale: { eq: $language } }
    ) {
      nodes {
        slug
        title
      }
    }
    allContentfulRecruitmentLocation(
      filter: { node_locale: { eq: $language } }
    ) {
      nodes {
        slug
        title
      }
    }
    allContentfulRecruitmentType(filter: { node_locale: { eq: $language } }) {
      nodes {
        slug
        title
      }
    }
  }
`;
