import { Pagination, Select } from "antd";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useMemo, useState } from "react";
import { RecruitmentPageQuery } from "../../../../graphql-types";
import { SkewMainContainer } from "../../../components/Container";
import { SectionDescribe, SectionTitle } from "../../../components/Title";
import { current_position } from "../../../images/recruitment";
import { useMediaScreen } from "../../../utils/utils";
import CurrentJobInfo from "./CurrentJobInfo";
import JobItem from "./JobItem";

type RecruitmentDepartment =
  RecruitmentPageQuery["allContentfulRecruitingDepartment"]["nodes"][number];

type RecruitmentType =
  RecruitmentPageQuery["allContentfulRecruitmentType"]["nodes"][number];

type RecruitmentLocation =
  RecruitmentPageQuery["allContentfulRecruitmentLocation"]["nodes"][number];

export type RecruitmentJob =
  RecruitmentPageQuery["allContentfulJobs"]["nodes"][number];

export type FilterItem = {
  slug: string;
  title: string;
};

const { Option } = Select;
const CurrentPositionView = ({
  departments,
  types,
  locations,
  jobs,
}: {
  departments: RecruitmentDepartment[];
  types: RecruitmentType[];
  locations: RecruitmentLocation[];
  jobs: RecruitmentJob[];
}) => {
  const { t } = useTranslation();
  const [type, setType] = useState("all");
  const [department, setDepartment] = useState("all");
  const [location, setLocation] = useState("all");
  const [pageNumber, setPageNumber] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentJob, setCurrentJob] = useState<RecruitmentJob>(null);
  const { isSmScreen } = useMediaScreen();
  const showJobs = useMemo(
    () =>
      jobs
        .filter((j: RecruitmentJob) =>
          department === "all" ? true : j.department?.slug === department
        )
        .filter((j: RecruitmentJob) =>
          location === "all"
            ? true
            : j.location.map((l: FilterItem) => l.slug).includes(location)
        )
        .filter((j: RecruitmentJob) =>
          type === "all"
            ? true
            : j.type.map((l: FilterItem) => l.slug).includes(type)
        ),
    [type, department, location, jobs]
  );
  return (
    <SkewMainContainer fillClassName="white-bg" className="pt-0">
      <div className="flex flex-col items-center">
        <img className="w-16" src={current_position} alt="" />
        <SectionTitle className="text-center">{t("当前招聘职位")}</SectionTitle>
        <SectionDescribe className="text-center">
          {t("欢迎将您的简历投递至 hr@stardust.ai")}
        </SectionDescribe>
        <div className="grid grid-cols-3 gap-6 w-full mt-16">
          <Select
            size="large"
            className="text-sm rounded"
            value={department}
            onChange={(value) => setDepartment(value)}
          >
            <Option value="all">
              {isSmScreen ? t("所有部门") : t("部门")}
            </Option>
            {departments.map(({ slug, title }) => (
              <Option key={slug} value={slug}>
                {title}
              </Option>
            ))}
          </Select>
          <Select
            size="large"
            className="text-sm rounded"
            value={type}
            onChange={(value) => setType(value)}
          >
            <Option value="all">
              {isSmScreen ? t("所有招聘类型") : t("招聘类型")}
            </Option>
            {types.map(({ slug, title }) => (
              <Option key={slug} value={slug}>
                {title}
              </Option>
            ))}
          </Select>
          <Select
            size="large"
            className="text-sm rounded"
            value={location}
            onChange={(value) => setLocation(value)}
          >
            <Option value="all">
              {isSmScreen ? t("所有地点") : t("地点")}
            </Option>
            {locations.map(({ slug, title }) => (
              <Option key={slug} value={slug}>
                {title}
              </Option>
            ))}
          </Select>
        </div>
        <div className="w-full mt-8">
          {showJobs
            .slice((pageNumber - 1) * 10, pageNumber * 10)
            .map((job, index) => (
              <JobItem
                key={index}
                job={job}
                onClick={() => {
                  if (job.jobUrl) {
                    window.open(job.jobUrl, "_blank");
                    return;
                  }
                  setIsModalVisible(true);
                  setCurrentJob(job);
                }}
              />
            ))}
        </div>
        <Pagination
          current={pageNumber}
          onChange={(num) => setPageNumber(num)}
          className="mt-8"
          defaultCurrent={1}
          total={showJobs.length}
          defaultPageSize={10}
        />
      </div>
      <CurrentJobInfo
        currentJob={currentJob}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </SkewMainContainer>
  );
};

export default CurrentPositionView;
