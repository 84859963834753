import { Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useRef } from "react";
import { MainContainer, SkewContainer } from "../../components/Container";
import {
  StripeColor,
  StripeColorContainer,
} from "../../components/StripeColor";
import { SectionTitle } from "../../components/Title";
import {
  Heartfelt_Hanlu_avatar,
  Heartfelt_Hanlu_cover,
  Heartfelt_Hanlu_voice,
  Heartfelt_Kunqi_avatar,
  Heartfelt_Kunqi_cover,
  Heartfelt_Kunqi_voice,
  Heartfelt_Lizhe_avatar,
  Heartfelt_Lizhe_cover,
  Heartfelt_Lizhe_voice,
  Heartfelt_Wangkaikai_avatar,
  Heartfelt_Wangkaikai_cover,
  Heartfelt_Wangkaikai_voice,
  Heartfelt_Wenhao_avatar,
  Heartfelt_Wenhao_cover,
  Heartfelt_Wenhao_voice,
  RecruitmentLeftArrow,
  RecruitmentRightArrow,
} from "../../images/recruitment";
import { CustomerAvatar } from "../../pages/customer-case";

const VoiceEmployeesView = () => {
  const { t } = useTranslation();
  const ref = useRef<CarouselRef>(null);
  const lists = [
    {
      cover: Heartfelt_Hanlu_cover,
      avatar: Heartfelt_Hanlu_avatar,
      companyName: t("运营部"),
      position: t("项目经理"),
      name: t("韩露"),
      words: t(
        "星尘是我的第一份工作，许是缘分使然，公司的文化和氛围，正在做的以及想要做的事都与我的很多理念一致，于是我选择成为星尘的一员。在这里，让我感受最深刻的是星尘支持每一名成员最大限度的发挥潜力，对于任何事情都是u can u up。这也让我不断突破，甚至逐渐享受打怪升级的快感，偶尔回首，发现自己的成长的确是清晰可见的。项目经理面临的挑战不可谓不艰巨，交付靠推进，产品需迭代，算法求反馈，客户得维护，团队要周旋……幸运的是星尘鼓励创新，允许试错，也因此我可以放开手脚，最大化自己的能量！"
      ),
      audioUrl: Heartfelt_Hanlu_voice,
    },
    {
      cover: Heartfelt_Lizhe_cover,
      avatar: Heartfelt_Lizhe_avatar,
      companyName: t("产品部"),
      position: t("产品经理"),
      name: t("李哲"),
      words: t(
        "目前我入职星尘已快两年，负责星尘标注平台的开发迭代和项目管理工作。对我来说，星尘拥有从0到1的产品机会，可以逐步理解大数据下的 SaaS 平台设计、算法研发、敏捷开发等各个方面；星尘像是一口“井”，不设限的人能够在这挖到更多的“水”；星尘允许和提供犯错的机会，并鼓励有承担责任、任务的勇气；星尘支持创新和改革，任何员工的优质建议和决策能被听到并被采纳；星尘鼓励轻松幽默的工作环境，同时支持就事论事的争论和批评；星尘业务不断快速增长，能够让人展望到一个预期的未来；这就是我理解下的星尘数据。"
      ),
      audioUrl: Heartfelt_Lizhe_voice,
    },
    {
      cover: Heartfelt_Wangkaikai_cover,
      avatar: Heartfelt_Wangkaikai_avatar,
      companyName: t("技术部"),
      position: t("后端工程师"),
      name: t("王凯凯"),
      words: t(
        "'我们希望大家目标一致，但又各有能力，并且有着对目标负责的独立人格'，这是星尘的价值观之一，也是我加入星尘大家庭的重要原因。在开放包容、鼓励创新的氛围中工作非常充实，大家努力工作、聪明地解决问题。作为一名技术研发人员，自己参与研发出来的产品能够被客户认可采纳，这让我感到非常开心。另外，公司的愿景是“成为人工智能时代的数据中台”，我们的工作直接关系到AI训练数据高质量低成本生产，有幸作为团队的一员，感到很有成就感。此外，根据市场的反馈，不断研发改进更好的产品也是我们技术人心之所向。"
      ),
      audioUrl: Heartfelt_Wangkaikai_voice,
    },
    {
      cover: Heartfelt_Kunqi_cover,
      avatar: Heartfelt_Kunqi_avatar,
      companyName: t("产品部"),
      position: t("产品实习生"),
      name: t("汪琨琪"),
      words: t(
        "没想到时间过的这么快，一转眼来星尘半年了。感谢星尘让我在第一份实习中就能从懵懂的学生迅速蜕变为能搞点事情的产品汪。星尘一大特点是敏捷。团队采用的敏捷开发协作模式，有规定的流程保证了快而不乱，不受限制是因为如果有更紧急的事情也可以插队，需要谁帮忙他们都会伸出援手。 星尘另一大特点是扁平，在这能真真切切感受到这里没有明显的等级划分，上到 CEO 下到实习生，每一个人都在真诚、平等地协作。我喜欢这里的同事有工匠精神，精心打磨一款产品，客户第一，成就价值。期待乘风破浪，跟大家一起飞，成为 AI 行业最闪亮的那颗星！"
      ),
      audioUrl: Heartfelt_Kunqi_voice,
    },
    {
      cover: Heartfelt_Wenhao_cover,
      avatar: Heartfelt_Wenhao_avatar,
      companyName: t("技术部"),
      position: t("算法实习生"),
      name: t("闻豪"),
      words: t(
        "星尘是我实习的第一家公司，公司的朋友们都很Nice，我在这里收获许多。星尘的特点就是快。每个人聚焦于自己的岗位，通过高效的沟通、协作及稳定的执行力，取得一场场快攻的胜利。包容”两个字写在了公司的文化里。星尘允许试错，每个人无论是犯了错误还是遇到了困难，都可以在彼此平等、相互尊重的沟通中合理解决问题。星尘不缺的是数据，它们是驱动数据科学的“石油”。算法工程师可以在这里开放自己的创造力，着手解决业务中遇到的问题，不断获得成就感。"
      ),
      audioUrl: Heartfelt_Wenhao_voice,
    },
  ];
  return (
    <SkewContainer
      fillClassName="light-white-bg z-10"
      skewTopChildren={
        <>
          <StripeColorContainer className="-top-8">
            <StripeColor className="bg-[#ADE6FF] w-64" />
          </StripeColorContainer>
          <StripeColorContainer className="right-0 -top-8">
            <StripeColor className="bg-[#6775DE] w-80" />
          </StripeColorContainer>
        </>
      }
      skewBottomChildren={
        <>
          <StripeColorContainer className="-bottom-8 right-0">
            <StripeColor className="bg-[#F6D67A] w-80" />
          </StripeColorContainer>
        </>
      }
    >
      <MainContainer>
        <SectionTitle>{t("员工心声")}</SectionTitle>
        <div className="relative">
          <Carousel
            className="rounded-lg shadow-offset-30 mt-8 voice-employees"
            ref={ref}
            dots={{ className: "index-customer-dot voice-employees-dot" }}
          >
            {lists.map((list, index) => (
              <div
                key={index}
                className="bg-white rounded-lg p-6 sm:pl-5 sm:pr-16 "
              >
                <div className="grid grid-cols-1 sm:grid-cols-8 gap-8">
                  <img
                    className="sm:w-60 w-full sm:col-span-2"
                    src={list.cover}
                    alt=""
                  />
                  <div className="sm:col-span-6">
                    <CustomerAvatar
                      type="static"
                      position={list.position}
                      name={list.name}
                      avatar={list.avatar}
                      companyName={list.companyName}
                    />
                    <p className="mt-6 leading-7">"{list.words}"</p>
                    {list.audioUrl && (
                      <audio
                        className="transform scale-75 sm:-ml-10 -ml-8 mt-6"
                        controls
                        src={list.audioUrl}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
          <RecruitmentLeftArrow
            className="carousel-arrow -left-12 cursor-pointer"
            onClick={() => ref.current?.prev()}
          />
          <RecruitmentRightArrow
            className="carousel-arrow -right-12 cursor-pointer"
            onClick={() => ref.current?.next()}
          />
        </div>
      </MainContainer>
    </SkewContainer>
  );
};

export default VoiceEmployeesView;
