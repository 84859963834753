import classNames from "classnames";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { FC, memo, useState } from "react";
import {
  ContentfulImage200x200Fragment,
  CustomerCasePageQuery,
  ServiceScenarioDetailPageQuery,
} from "../../graphql-types";
import Button, { ShowMoreButton } from "../components/Button";
import {
  MainContainer,
  PageContainer,
  SkewContainer,
} from "../components/Container";
import ContentfulImage from "../components/ContentfulImage";
import Footer from "../components/Footer";
import Header, { ThemeEnum } from "../components/Header";
import LogoAnimationBackground from "../components/LogoAnimation";
import Metadata from "../components/Metadata";
import SearchInput from "../components/SearchInput";
import SkewGradientBg from "../components/SkewGradientBg";
import { PageDescribe, PageTitle } from "../components/Title";
import { WaterfallFlow } from "../components/WaterfallFlow";
import { CustomerCase } from "../images/customerCase";
import { usePageMetadata } from "../metadata/page";
import { Element } from "../utils/utils";
import LearnMoreView from "../Views/LearnMoreView";
import { PageTitleWithIcon } from "./price";

export type CustomerInfo = {
  className?: string;
  words?: string;
  companyName: string;
  position: string;
  name: string;
  avatar: ContentfulImage200x200Fragment;
  type?: "contentful" | "static";
};

export const CustomerAvatar = memo(
  ({
    avatar,
    name,
    companyName,
    position,
    type = "contentful",
  }: CustomerInfo) => {
    return (
      <div className="flex items-center">
        {type === "contentful" ? (
          <ContentfulImage className="w-16 h-16 rounded-full" image={avatar} />
        ) : (
          <img className="w-16 h-16 rounded-full" src={avatar} alt="" />
        )}
        <div className="ml-4">
          <p className="font-bold">{name}</p>
          {/* <p className="text-xs mt-1">
            {companyName}、{position}
          </p> */}
        </div>
      </div>
    );
  }
);

type CustomerCaseItem =
  | CustomerCasePageQuery["allContentfulCaseStudy"]["nodes"][number]
  | Element<
      NonNullable<
        ServiceScenarioDetailPageQuery["contentfulScenario"]
      >["related_cases"]
    >;

export const RenderCustomerCases = memo(
  ({
    relatedCases,
    className,
  }: {
    relatedCases: CustomerCaseItem[];
    className?: string;
  }) => {
    return (
      <WaterfallFlow className={classNames(className)}>
        {relatedCases.map((relatedCase, index) => (
          <a key={index} href={`/caseStudy/${relatedCase.slug}`}>
            <div className="mb-10 rounded-lg overflow-hidden news-card bg-white">
              <ContentfulImage
                className="w-full"
                image={relatedCase?.preview_logo!}
              />
              <div className="px-8 py-6">
                <h4 className="mt-6 text-lg font-bold">{relatedCase.title}</h4>
                {relatedCase?.customer?.length > 0 && (
                  <div className="mt-6 text-color-black">
                    <p className="leading-8 mb-2">
                      "{relatedCase.customer[0].words}"
                    </p>
                    <CustomerAvatar {...relatedCase.customer[0]} />
                  </div>
                )}
              </div>
            </div>
          </a>
        ))}
      </WaterfallFlow>
    );
  }
);

const CustomerCasePage: FC<{ data: CustomerCasePageQuery }> = ({
  data: { allContentfulCaseStudy },
}) => {
  const { t } = useTranslation();
  const [isShowMore, setShowMore] = useState(false);
  const customerCases = allContentfulCaseStudy?.nodes ?? [];
  const { customerCase } = usePageMetadata();
  return (
    <PageContainer>
      <Header defaultTheme={ThemeEnum.dark} />
      <Metadata {...customerCase} />
      <SkewGradientBg id="gradient-customer-case" />
      <MainContainer className="mb-20">
        <PageTitleWithIcon title={t("客户案例")} icon={<CustomerCase />} />
        <div className="flex flex-wrap relative">
          <div className="w-full sm:w-[460px]">
            <PageTitle>{t("与巨人同行，与智者为伍")}</PageTitle>
            <PageDescribe className="mt-6">
              {t(
                "专业的合作伙伴与客户，遍及互联网、智能设备、金融、遥感等多个领域"
              )}
            </PageDescribe>
          </div>
          <div className="relative sm:ml-16 mt-8 sm:mt-0 sm:block hidden">
            <div
              style={{ zIndex: -1 }}
              className="sm:absolute sm:-mt-20 rounded-md"
            >
              <LogoAnimationBackground />
            </div>
          </div>
        </div>
        <div className="w-full sm:w-[378px] mt-12">
          <SearchInput />
          <div className="flex justify-between mt-7">
            <Button onClick={() => navigate("/contact")}>
              {t("联系销售")}
            </Button>
          </div>
        </div>
      </MainContainer>
      <SkewContainer fillClassName="bg-gray-50 z-30">
        <MainContainer style={{ zIndex: 50 }}>
          <RenderCustomerCases
            className="pt-4"
            relatedCases={
              isShowMore ? customerCases : customerCases.slice(0, 10)
            }
          />
          {!isShowMore && customerCases.length > 10 && (
            <ShowMoreButton
              className="border"
              onClick={() => setShowMore(true)}
            >
              {t("显示更多")}
            </ShowMoreButton>
          )}
        </MainContainer>
      </SkewContainer>
      <LearnMoreView
        leftUrl={"/contact"}
        leftText={t("填写需求表格")}
        rightText={t("了解价格")}
        rightUrl={"/price"}
      />
      <Footer />
    </PageContainer>
  );
};

export default CustomerCasePage;
export const query = graphql`
  query CustomerCasePage($language: String!) {
    ...AllLangauages
    allContentfulCaseStudy(filter: { node_locale: { eq: $language } }) {
      nodes {
        slug
        title
        preview_logo {
          ...ContentfulImage200x200
        }
        customer {
          words
          companyName
          position
          avatar {
            ...ContentfulImage200x200
          }
          name
        }
      }
    }
  }
`;
