const CANVAS_HEIGHT = 640;
type BubbleItem = {
  scale: number;
  x: number;
  y: number;
  url: string;
  speed: number;
};

class Bubbles {
  private bubbles: Bubble[];

  constructor(specs: BubbleItem[], readonly bubbleContainer: HTMLDivElement) {
    this.bubbles = [];
    specs.forEach((spec, index) => {
      this.bubbles.push(new Bubble(index, spec, this.bubbleContainer));
    });
    requestAnimationFrame(this.animation.bind(this));
  }

  animation() {
    this.bubbles.forEach((bubble) => bubble.update(bubble.options.speed));
    requestAnimationFrame(this.animation.bind(this));
  }
}

class Bubble {
  private index;
  readonly options;
  readonly el: HTMLDivElement;

  constructor(
    index: number,
    options: BubbleItem,
    readonly bubbleContainer: HTMLDivElement
  ) {
    this.index = index;
    this.options = options;
    const img = document.createElement("img");
    img.src = options.url;
    this.el = document.createElement("div");
    this.el.className = "bubble";
    this.el.appendChild(img);
    bubbleContainer.appendChild(this.el);
  }

  update(speed: number) {
    const { x, y, scale } = this.options;
    this.options.y = y < -240 ? CANVAS_HEIGHT : y - speed;
    this.el.style.transform = `translate(${x}px, ${y}px) scale(${scale})`;
  }
}

export default Bubbles;
