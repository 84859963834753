import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { ChildrenProps } from "../../components/Container";
import Tabs from "../../components/Tabs";
import { SectionDescribe, SectionTitle } from "../../components/Title";
import {
  whyjoin_bd_01,
  whyjoin_bd_02,
  whyjoin_developer_01,
  whyjoin_developer_02,
  whyjoin_overview_01,
  whyjoin_overview_02,
  whyjoin_yunyin_01,
  whyjoin_yunyin_02,
} from "../../images/recruitment";

const List = (props: ChildrenProps) => {
  return (
    <div className="flex items-start">
      <div className="w-1 h-1 bg-[#455364] rounded-full flex-none mr-2 mt-2" />
      {props.children}
    </div>
  );
};

const WhyChooseUsView = () => {
  const { t } = useTranslation();
  const lists = [
    {
      title: t("概述"),
    },
    {
      title: t("运营"),
    },
    {
      title: t("技术"),
    },
    {
      title: t("商务"),
    },
    // {
    //   title: t("项目管理"),
    // },
  ];

  return (
    <div className="mb-20">
      <SectionTitle>{t("为什么选择我们")}</SectionTitle>
      <SectionDescribe className="mt-6">
        {t(
          "选择我们，你选择了机遇与挑战，你会同我们一起，去探索一个个有趣的问题和最佳的解决方案。"
        )}
      </SectionDescribe>
      <Tabs
        className="mt-12"
        type="gray"
        tabs={lists.map((list) => list.title)}
      >
        {/* 概述 */}
        <div>
          <div className="mt-12 grid grid-cols-1 sm:grid-cols-8">
            <div className="sm:col-span-5 sm:pr-20">
              <h4 className="text-lg font-bold mb-8">
                {t("为什么要加入星尘?")}
              </h4>
              <p className="leading-6 mb-8 text-color-blue-gary">
                <b className="text-yellow-600">{t("对于刚刚毕业的你")}</b>
                {t(
                  "，或许正在投着大厂的简历，同时也在投着几个“看着不错”的小公司。如果能去一线互联网公司，那么恭喜，你已经进入了头部的10%求职者行列。这意味着你在接下来的时间里，能体会到职业化的工作氛围、得到专业化的技能培训、也保证了将来较高的薪资议价权。但同时，你也会体会到“螺丝钉”的真正含义，一个项目拆解到你身上的时候，是一定不会超过你的能力范围的。同时，你也有可能参与到“内卷”的车祸现场，亲眼目睹什么是为了绩效考评而做出无效努力。也许，你会想，同样是燃烧青春，为什么不让它来得更猛烈一些，那么欢迎你考虑一下星尘。我们的信息全部透明，即使是实习生也能够快速了解任务背景；我们的工作极富挑战性，好不容易解决一个问题，领导会微笑地递给你3个更复杂的任务；我们的迭代反馈周期很快，正反馈让你每天累并快乐着。同样是5年工作经验，如果你想用2年去走完，那么欢迎来挑战。"
                )}
              </p>
              <p className="leading-6 mb-8 text-color-blue-gary">
                <b className="text-yellow-600">{t("对于已经工作2-5年的你")}</b>
                {t(
                  "，或许已经深谙职场规则，工作的激情逐渐暗淡。你手上有着几个知名公司的offer，面试的老板也很nice，工作内容是你熟悉的领域，HR催着你入职。但你的那颗“不安分”的心又开始作祟，让你在自己的舒适区边界不断试探着自己真正想要的工作。你回想起之前工作中，为了项目机会而消耗了非常多的精力、为了在内部竞争机制下脱颖而投入了全部心血、为了能安心做事而经历一些无关的破事，但最后却因为组织架构变动而项目人去楼空。如果这时候你内心仍然期待着一份可以甩开膀子大干一场的工作、一份可以真正做你热爱的事情而不用明争暗斗的工作、一份可以通过创造真正有意义的价值而影响结果的工作，那么星尘可能是你很好的一个选择。"
                )}
              </p>
              <p className="text-color-blue-gary">
                <b className="text-yellow-600">
                  {t("对于已经成为行业专家的你")}
                </b>
                {t(
                  "，一份普通的工作或许已经不能完整体现你的价值。猎头介绍过来的工作，或许可以让你的收入翻倍，但你很介意工作的内容、公司的方向、团队的文化。你不会因为钱而随随便便去一家公司打工，因为工作的质量和你付出的时间比什么都重要。"
                )}
              </p>
            </div>
            <img className="sm:col-span-3" src={whyjoin_overview_01} alt="" />
          </div>
          <div className="mt-12 grid grid-cols-1 sm:grid-cols-8">
            <img className="sm:col-span-3" src={whyjoin_overview_02} alt="" />
            <div className="sm:col-span-5 sm:pl-20 mt-12 sm:mt-0">
              <h4 className="text-lg font-bold mb-8">
                {t("什么样的人适合我们")}
              </h4>
              <List>
                <p className="leading-6 mb-7 text-color-blue-gary">
                  {t("时不时冒出创业的冲动，想去做个改变世界的产品；")}
                </p>
              </List>
              <List>
                <p className="leading-6 mb-7 text-color-blue-gary">
                  {t("打游戏默认选择“Hard”模式的人；")}
                </p>
              </List>
              <List>
                <p className="text-color-blue-gary mb-7">
                  {t("希望旁若无人地投入工作，而不是“看在钱的份上”；")}
                </p>
              </List>
              <List>
                <p className="text-color-blue-gary">
                  {t("选择很多，但更注重工作性质和工作质量；")}
                </p>
              </List>
            </div>
          </div>
        </div>
        {/* 运营 */}
        <div>
          <div>
            <div className="mt-12 grid grid-cols-1 sm:grid-cols-8">
              <div className="sm:col-span-5 sm:pr-20">
                <h4 className="text-lg font-bold mb-8">
                  {t("为什么选择星尘?")}
                </h4>

                <p className="leading-6 text-color-blue-gary">
                  {t("如果你想体验与富有智慧、充满狼性的团队协作是什么感觉；")}
                </p>
                <p className="leading-6 text-color-blue-gary">
                  {t(
                    "如果你想探寻质量、时间与成本是否存在终极平衡法，项目相管理如何实现多方共赢；"
                  )}
                </p>
                <p className="leading-6 text-color-blue-gary">
                  {t(
                    "如果你想知道各行各业的算法都在研究些什么，与高端前沿算法工程师密切合作；"
                  )}
                </p>
                <p className="leading-6 text-color-blue-gary">
                  {t(
                    "如果你想实现训练数据的标注自动化，高效生成高精度的训练数据。"
                  )}
                </p>
                <p className="leading-6 mb-8 text-color-blue-gary">
                  {t(
                    "在这里，这些问题都能得到实现。亦或许，我们将一同寻找答案！"
                  )}
                </p>

                <p className="leading-6 text-color-blue-gary">
                  {t(
                    "当客户是世界五百强、行业独角兽，当技术团队是硅谷大牛或是Github前十，"
                  )}
                </p>
                <p className="leading-6 text-color-blue-gary">
                  {t("怀有一身绝技的你绝不会感叹无处施展，")}
                </p>
                <p className="leading-6 text-color-blue-gary">
                  {t("为训练数据保生产，向敏捷开发提需求，对算法优化做反馈")}
                </p>
                <p className="leading-6 text-color-blue-gary">
                  {t("如果你是项目管理的资深玩家或潜力新星，那么来吧！")}
                </p>
                <p className="leading-6 text-color-blue-gary">
                  {t(
                    "有了你的加入，优质的训练数据、优秀的算法模型的银河将又多一颗闪亮的星。"
                  )}
                </p>
              </div>
              <img className="sm:col-span-3" src={whyjoin_yunyin_01} alt="" />
            </div>
            <div className="mt-12 grid grid-cols-1 sm:grid-cols-8">
              <img className="sm:col-span-3" src={whyjoin_yunyin_02} alt="" />
              <div className="sm:col-span-5 sm:pl-20 mt-12 sm:mt-0">
                <h4 className="text-lg font-bold mb-8">
                  {t("希望你是什么样的")}
                </h4>
                <List>
                  <p className="text-color-blue-gary">
                    {t(
                      "对新事物的好奇：快速学习的能力和自主学习的动力，将助力你成为团队中的佼佼者。脚踏实地固然重要，但在这里我们同样喜欢仰望星空"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary">
                    {t("细致与负责：细致帮助你规避风险，负责帮助你实现结果")}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary">
                    {t(
                      "高效的沟通：掌握高效沟通的技巧，在各个项目相关方中灵活穿梭、穿丝成线，互相成就"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary">
                    {t(
                      "自驱和执行：作为项目的首席负责人，任何项目要素都由你直接或间接推动，效率和结果导向是我们的追求"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary">
                    {t(
                      "自逻辑与思考：无论是需求对接还是项目管理，逻辑性强的你会发现工作变得很容易，常思考的你总能发现更好的工作方式，自我迭代亦或沉淀分享"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary">
                    {t(
                      "拥抱技术：代码还是算法，热爱技术的你拥护核心竞争力的同时也在拥抱未来"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary">
                    {t(
                      "积极协作：1+1=?的问题在协作中将被重新定义，积极协作的你一定可以帮助团队，更能成就自己"
                    )}
                  </p>
                </List>
                <List>
                  <p className="leading-6 mb-8 text-color-blue-gary">
                    {t("我们欢迎你与星尘共同成长、一起征服星辰大海！")}
                  </p>
                </List>
              </div>
            </div>
          </div>
        </div>
        {/* 技术 */}
        <div>
          <div>
            <div className="mt-12 grid grid-cols-1 sm:grid-cols-8">
              <div className="sm:col-span-5 sm:pr-20">
                <h4 className="text-lg font-bold mb-8">
                  {t("我们是谁，我们在做什么，What makes us unique?")}
                </h4>
                <List>
                  <p className="leading-6 mb-8 text-color-blue-gary">
                    {t(
                      "我们是一群极度热爱技术的工程师，我们在国内外的公司、项目中做出过非常核心的贡献。我们的成员曾经创造过在github 上面曾排名第一的Swift开源项目。我们也有人从0开始独自实现了一套和ReactNative类似的框架和工具链。我们除了对技术的完美追求，同时也关注技术对于人类各方面的影响力。我们是一个非常和睦的团队，我们不存在传统意义的管理者，每个人都是自己的管理者，我们没有身份的差异，我们只是一群互相尊重的知识工作者，互相探讨问题，互相学习，一起成长。"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary">
                    {t(
                      "我们现在主要做的事情是数据标注，结构化的数据是人工智能行业的石油。这是一个一眼看来很简单的事情，但仔细去想象和思考却又非常耐人寻味的问题。我们在开发一套效率极高，通用型极强的全方位揽括几乎所有数据类型的标注平台。"
                    )}
                  </p>
                </List>
              </div>
              <img
                className="sm:col-span-3"
                src={whyjoin_developer_01}
                alt=""
              />
            </div>
            <div className="mt-12 grid grid-cols-1 sm:grid-cols-8">
              <img
                className="sm:col-span-3"
                src={whyjoin_developer_02}
                alt=""
              />
              <div className="sm:col-span-5 sm:pl-20 mt-12 sm:mt-0">
                <h4 className="text-lg font-bold mb-8">
                  {t("希望你是什么样的")}
                </h4>
                <List>
                  <p className="leading-6 mb-7 text-color-blue-gary">
                    {t(
                      "对代码的热爱：我们希望你对知识充满好奇，渴望成长，谦虚，辩证思考，认真对待细节。我们希望你能够热爱开发软件。写代码对我们来说是一件快乐的事情，我们团队的每一个人都很谦虚，因为谦虚的去思考才能辩证的去看待问题。"
                    )}
                  </p>
                </List>
                <List>
                  <p className="leading-6 mb-7 text-color-blue-gary">
                    {t(
                      "关注技术影响力：技术可以解决很多有意思的问题，然后解决了这些问题之后又能实实在在的影响别人，甚至改变每一个人的生活。"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary mb-7">
                    {t(
                      "技术的前瞻性：我们所使用的技术往往是世界最前沿的技术。因为我们笃定技术对于生产力的影响。但新技术带来的除了新的解决问题的思路和方法，同时也会带来其对应的问题。有时候我们会给开源社区提反馈，有时候也会提PR。我希望你也能够认同和热爱这样的事情。"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary">
                    {t(
                      "创造优雅的代码：你的代码不仅会被用户使用，同时也会被团队内的其他人使用。这是一件让人非常有成就感的事情，我们都会为你所创造的优雅的代码对你心存感激。"
                    )}
                  </p>
                </List>
              </div>
            </div>
          </div>
        </div>
        {/* 商务 */}
        <div>
          <div>
            <div className="mt-12 grid grid-cols-1 sm:grid-cols-8">
              <div className="sm:col-span-5 sm:pr-20">
                <h4 className="text-lg font-bold mb-8">
                  {t("你想成为无所不能的商务达人吗？")}
                </h4>
                <List>
                  <p className="leading-6 mb-8 text-color-blue-gary">
                    {t(
                      "服务于行业内领先的客户群体，不仅可以给到客户最专业的方案，更可以迭代出标准化的商务流程，甚至可以迭代产品，还可以经常组织线下活动。"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary">
                    {t(
                      "了解行业最前沿的技术及最新动态，与算法科学家一起讨论算法，完成丰富多样的场景中的标注需求，满足客户的需求甚至帮助解决客户更加复杂的难题，你的才华都可以实现。"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary">
                    {t(
                      "学习优秀的产品，让产品随时成为你最有力的工具，此外也可以在主流媒体上发表博客或者文章。"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary">
                    {t(
                      "无处不在的学习渠道与内容分享，在潜移默化中获得良师益友，在这里，没有上下级，只有一起不断成长的伙伴与搭档。"
                    )}
                  </p>
                </List>
              </div>
              <img className="sm:col-span-3" src={whyjoin_bd_01} alt="" />
            </div>
            <div className="mt-12 grid grid-cols-1 sm:grid-cols-8">
              <img className="sm:col-span-3" src={whyjoin_bd_02} alt="" />
              <div className="sm:col-span-5 sm:pl-20 mt-12 sm:mt-0">
                <h4 className="text-lg font-bold mb-8">
                  {t("希望你是什么样的")}
                </h4>
                <List>
                  <p className="leading-6 mb-7 text-color-blue-gary">
                    {t(
                      "学习是一件长期的事情，只有熟练掌握产品才能让你在与客户的交流中立于不败之地，我们希望你能够有良好的基础知识，理解行业术语，平时能够自主学习相关知识，最重要的是能够举一反三。"
                    )}
                  </p>
                </List>
                <List>
                  <p className="leading-6 mb-7 text-color-blue-gary">
                    {t(
                      "沟通也是一门艺术，不论是在与客户的交流，还是与团队同事的沟通中，我们都要有逻辑的去输出自己的观点，你会发现问题也会被更快的解决。"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary mb-7">
                    {t(
                      "事情往往不仅仅是表面看起来那样，所以足够的敏感才会让你分析出最本质的原因，帮助你做出最正确的决定，希望你能持续且深入的思考。"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary">
                    {t(
                      "在这里时间不止一个，有时差的沟通也会让我们对时间有不一样的认识，所以和海外的客户对接也会时常发生，你会接触到更多不一样的群体和更多样的需求。"
                    )}
                  </p>
                </List>
                <List>
                  <p className="text-color-blue-gary">
                    {t(
                      "一个人可以走很快，一群人才能走的更远，你想成为带领团队的那个人吗，如果你有勇气，在星尘都可以实现。"
                    )}
                  </p>
                </List>
              </div>
            </div>
          </div>
        </div>
        {/* 项目管理 */}
        {/* <div>coming soon...</div> */}
      </Tabs>
    </div>
  );
};

export default WhyChooseUsView;
