import * as React from "react";
import { useLayoutEffect, useRef } from "react";
import {
  a10,
  a11,
  a12,
  a13,
  a14,
  a15,
  a16,
  a17,
  a18,
  a19,
  a20,
  a21,
  a22,
  a23,
} from "../../images/index/index";
import Bubbles from "./logoAnimation";
import "./logoAnimation.css";

const LogoAnimationBackground = () => {
  const bubblesEl = useRef<HTMLDivElement>(null);
  const brands = [
    a10,
    a11,
    a12,
    a13,
    a14,
    a16,
    a17,
    a18,
    a15,
    a19,
    a20,
    a21,
    a22,
    a23,
  ].sort(() => Math.random() - 0.5);
  const scales = [0.6, 0.65, 0.7, 0.75, 0.8];
  const speeds = [0.3, 0.5, 0.7];
  const offsets = [5, -5];
  const bubbleSpecs = [
    { x: 195, y: 55 },
    { x: 470, y: 79 },
    { x: 480, y: 274 },
    { x: 95, y: 226 },
    { x: 310, y: 240 },
    { x: 280, y: 480 },
    { x: 83, y: 410 },
    { x: 480, y: 415 },
    { x: 520, y: 612 },
    { x: 343, y: 640 },
    { x: 103, y: 608 },
    { x: 60, y: 800 },
    { x: 280, y: 778 },
    { x: 420, y: 780 },
  ].map((item, index) => {
    return {
      url: brands[index],
      scale: scales[Math.floor(Math.random() * scales.length)],
      speed: speeds[Math.floor(Math.random() * speeds.length)],
      x: item.x + offsets[Math.floor(Math.random() * offsets.length)],
      y: item.y,
    };
  });
  useLayoutEffect(() => {
    if (bubblesEl.current) {
      new Bubbles(bubbleSpecs, bubblesEl.current);
    }
  }, []);
  return (
    <div className="bubble-wrap">
      <div ref={bubblesEl} className="w-[594px] h-[552px] overflow-hidden" />
    </div>
  );
};

export default LogoAnimationBackground;
