import classNames from "classnames";
import * as React from "react";
import { ReactNode } from "react";
import { useMediaScreen } from "../utils/utils";
import { ChildrenProps } from "./Container";

export const WaterfallColl = (props: ChildrenProps) => {
  return <div className="flex flex-col">{props.children}</div>;
};

export const WaterfallFlow = ({
  children,
  className,
}: {
  children: ReactNode[];
  className?: string;
}) => {
  const { isSmScreen } = useMediaScreen();
  return (
    <div
      className={classNames(
        "grid grid-cols-1 sm:grid-cols-2 gap-10",
        className
      )}
    >
      {!isSmScreen && <>{children}</>}
      {isSmScreen && (
        <>
          <WaterfallColl>
            {children
              .filter((_, idx: number) => idx % 2 === 0)
              .map((child) => child)}
          </WaterfallColl>
          <WaterfallColl>
            {children
              .filter((_, idx: number) => idx % 2 !== 0)
              .map((child) => child)}
          </WaterfallColl>
        </>
      )}
    </div>
  );
};
