import { Modal } from "antd";
import classNames from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { ReactNode } from "react";
import { FilterItem, RecruitmentJob } from "./CurrentPositionView";

const CurrentJobInfo = ({
  isModalVisible,
  setIsModalVisible,
  currentJob,
}: {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  currentJob: RecruitmentJob | null;
}) => {
  const { t } = useTranslation();
  if (currentJob == null) {
    return null;
  }
  return (
    <Modal
      width={640}
      footer={null}
      centered
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
    >
      <h3 className="text-2xl mb-8">后端开发工程师</h3>
      <div className="grid grid-cols-2 gap-2">
        <p>
          <ModalDescText>{t("部门: ")}</ModalDescText>
          <ModalDescText>{currentJob?.department?.title}</ModalDescText>
        </p>
        <p>
          <ModalDescText>{t("招聘类型: ")}</ModalDescText>
          <ModalDescText>
            {currentJob?.location.map((l: FilterItem) => l.title).join("/")}
          </ModalDescText>
        </p>
        <p>
          <ModalDescText>{t("地点: ")}</ModalDescText>
          <ModalDescText>
            {currentJob?.type.map((l: FilterItem) => l.title).join("/")}
          </ModalDescText>
        </p>
      </div>
      <p className="mt-8">{currentJob?.details.details}</p>
      <div
        className="rounded-md  bright-blue w-full h-10 text-center text-white leading-10 mt-8 cursor-pointer"
        onClick={() => window.open("mailto: hr@stardust.ai", "_blank")}
      >
        {t("投递简历")}
      </div>
      <p className="text-center mt-2 text-[#8089AC]">
        {t("欢迎将您的简历投递至 hr@stardust.ai")}
      </p>
    </Modal>
  );
};

const ModalDescText = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <span className={classNames("text-color-blue-gary", className)}>
      {children}
    </span>
  );
};
export default CurrentJobInfo;
