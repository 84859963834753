import classNames from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { ReactNode } from "react";
import { ChildrenProps } from "../../../components/Container";
import { JobIcon } from "../../../images/recruitment";
import { FilterItem, RecruitmentJob } from "./CurrentPositionView";

const JobItem = ({
  onClick,
  job,
}: {
  job: RecruitmentJob;
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="light-white-bg rounded  cursor-pointer mb-3 overflow-x-scroll"
      onClick={onClick}
    >
      <div className="min-w-[1048px] sm:min-w-0  grid grid-cols-4 px-8 h-16">
        <Col>
          <JobIcon />
          <p className="text-color-primary ml-4">{job.name}</p>
        </Col>
        <Col>
          <JobLabel>{t("部门：")}</JobLabel>
          <JobText>{job.department.title}</JobText>
        </Col>
        <Col>
          <JobLabel>{t("地点：")}</JobLabel>
          <JobText>
            {job.location?.map((l: FilterItem) => l.title).join("/")}
          </JobText>
        </Col>
        <Col className="justify-end">
          <JobLabel>{t("招聘类型：")}</JobLabel>
          <JobText>
            {job.type?.map((l: FilterItem) => l.title).join("/")}
          </JobText>
        </Col>
      </div>
    </div>
  );
};

const JobLabel = ({ children }: { children: ReactNode }) => {
  return <span className="text-color-666 text-sm">{children}</span>;
};

const JobText = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <span className={classNames("text-color-black text-sm", className)}>
      {children}
    </span>
  );
};

const Col = ({ className, children }: ChildrenProps) => {
  return (
    <div className={classNames("flex items-center", className)}>{children}</div>
  );
};

export default JobItem;
