import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { MainContainer, SkewContainer } from "../../components/Container";
import { SectionDescribe, SectionTitle } from "../../components/Title";
import { industry_prospects } from "../../images/recruitment";

const IndustryProspectsView = () => {
  const { t } = useTranslation();
  return (
    <SkewContainer fillClassName="bg-white">
      <MainContainer>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div>
            <SectionTitle>{t("行业前景")}</SectionTitle>
            <SectionDescribe className="mt-6">
              {t(
                "当前，国外AI数据巨头已经集体进入上市流程，市值达到500-1000亿人民币阶段；而国内早期成立的数据公司因为缺少核心技术，整体在小规模徘徊。最新成立的星尘数据，由硅谷算法专家带队，通过自研的核心技术每年以3-5倍的速度快速发展。随着人工智能的全球化的发展，大国博弈即将会进入白热化阶段，而数据也必将成为AI竞争的核心资源。我们星尘一定争取成为中国人工智能发展的加速器，不让中国在国际AI竞赛中被卡脖子！"
              )}
            </SectionDescribe>
          </div>
          <img src={industry_prospects} alt="" />
        </div>
      </MainContainer>
    </SkewContainer>
  );
};

export default IndustryProspectsView;
